<!--
  Uses: Icon slot with self spacing
  <template slot="icon">
    <i class="flaticon-reading-book text-2xl mr-2"></i>
  </template>
-->
<template>
  <div class="tab-component">
    <slot></slot>
    <div class="block">
      <div  :class="classes" class="inline-block border-b border-b-solid border-gray-300" v-for="(item, index) in items"  :key="index">
        <template>
          <router-link
              class="mx-3 py-2 text-gray-500 pointer flex justify-center align-flex-start mt-4"
              :to="{name: item.path, params: { id: item.id }}"
              :active-class="activeClass ? activeClass : 'active'"
              :style="{'pointer-events': item.status && item.status === 'lock' ? 'none' : ''}"
              @click.native="onClick"
            >
              <slot name="icon" :item="item"></slot>
              <span :style="{color: item.color}" :class="itemClass" class="text-base ml-3">
                {{ item.name }} 
                <md-icon v-if="item.status && item.status === 'lock'" class="text-base ml-1">lock</md-icon>
              </span>
            </router-link>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TabRouter",
  props: {
    items: {
      type: Array,
      required: true,
    },
    itemClass: {
      type: String,
      default: 'text-uppercase'
    },
    classes: {
      type: String,
      default: '',
    },
    activeClass: {
      type: String,
      default: '',
    }
  },
  methods: {
      onClick() {
          this.$emit('onClick')
      }
  }

};
</script>
<style lang="scss">
.tab-component .active {
  font-weight: bold;
  border-bottom: 1px solid rgb(73, 73, 73);
  margin-bottom: -3px;
}
</style>
