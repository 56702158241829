<template>
    <div class="p-6" style="width: 650px">
        <DialogTitle style="margin-left: -10px; margin-right: -10px" :label="content.taskName" align="center" />
      
        <Content class="my-3" label="Task ID" :text="content.taskId" />
        <Content class="my-3" label="Assign By" :text="content.assignBy" />
        <div class="md-layout md-gutter">
            <div class="md-layout-item md-xsmall-size-100 md-large-size-30">
            <Content class="my-3" label="Assign At" :text="content.assignAt" />
            </div>
            <div class="md-layout-item md-xsmall-size-100 md-large-size-70">
            <Content class="my-3" label="Deadline" :text="content.deadline" />
            </div>
        </div>

        <Content class="my-3" label="Status" :text="content.status" text-color="text-green" />
        <Content class="my-3" label="Award Point" :text="content.point.toString()"/>
        <div class="flex justify-center">
            <md-button class="outline-gray-400 text-uppercase rounded" @click="dialog(false)">Back</md-button>
        </div>
    </div>
</template>

<script>
import { DialogTitle } from "@/components";
import Content from "@/components/atom/Content";
import { mapMutations } from "vuex";
export default {
  components: {
    DialogTitle,
    Content
  },
  props: {
      content: {
          type: Object
      }
  },
  methods: mapMutations({
      dialog: "setShowDialog",
  })
}
</script>