<template>
  <div>
    <Tab :items="items">
        <template v-slot:icon="{ item }">
          
          <md-icon class="m-0">{{ item.icon }}</md-icon>
        </template>
    </Tab>
    <router-view @on-click="onClick" />
    <!-- Dialog Start -->
    <Dialog >
      <components :content="content" :is="component"></components>
    </Dialog>
    <!-- Dialog Start -->
  </div>
</template>

<script>
import Tab from '@/components/atom/Tab';
import ShowDetailsAwardPoint from "@/components/molecule/crm/ShowDetailsAwardPoint";
import ShowDetailsPerformanceChecking from "@/components/molecule/crm/showDetailsPerformanceChecking";
import { Dialog } from "@/components";
import { mapMutations } from "vuex";
export default {
  name: 'CRM-Index',
  components: {
      Tab,
      Dialog,
      ShowDetailsAwardPoint,
      ShowDetailsPerformanceChecking
  },
  data() {
    return {
      component: 'ShowDetailsAwardPoint',
      content: {},
      items: [
        {name: 'Award Points', path: 'crm.awardPoint', icon: 'emoji_events'},
        {name: 'Performance Checking', path: 'crm.performanceChecking', icon: 'bar_chart'},
      ]
    }
  },
  methods: {
    ...mapMutations({
      dialog: "setShowDialog",
    }),
    onClick({item, component}) {
      this.component = component;
      this.content = item;
      this.dialog(true);
    }
  }
}
</script>